var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('div',[_c('h1',{staticClass:"text-h4 mb-8 d-flex align-center flex-wrap"},[_c('div',[_vm._v(" Lista użytkowników "),_c('p',{staticClass:"text-body-2 grey--text"},[_vm._v(" mających dostęp do panelu administracyjnego ")])]),_c('v-spacer'),(_vm.$store.getters['auth/userHasPermissions']('users.create'))?_c('v-btn',{staticClass:"my-2",attrs:{"color":"success","small":""},on:{"click":function($event){$event.stopPropagation();_vm.create_dialog = true}}},[_vm._v(" Stwórz użytkownika ")]):_vm._e()],1),_c('v-sheet',{staticClass:"pa-4 mb-8",attrs:{"color":"grey lighten-4","rounded":""}},[_c('h4',{staticClass:"text-h6 mt-0 mb-2"},[_vm._v(" Filtry ")]),_c('v-row',[_c('v-col',{attrs:{"xs":"12","md":"4"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Status konta","items":[
                            {
                                text: 'Wszystkie statusy',
                                value: ''
                            },
                            {
                                text: _vm.$store.state.USER_STATUSES_LABELS['active'],
                                value: 'active'
                            },
                            {
                                text: _vm.$store.state.USER_STATUSES_LABELS['blocked'],
                                value: 'blocked'
                            },
                            {
                                text: _vm.$store.state.USER_STATUSES_LABELS['suspended'],
                                value: 'suspended'
                            }
                        ],"value":_vm.filter_status,"hide-details":""},on:{"change":_vm.onFilterStatus}})],1),_c('v-col',{attrs:{"xs":"12","md":"4"}},[_c('v-select',{attrs:{"outlined":"","label":"Poziom uprawnień","items":[
                            {
                                text: 'Wszystkie poziomy',
                                value: ''
                            },
                            {
                                text: _vm.$store.state.USER_ROLE_LABELS['admin'],
                                value: 'admin'
                            }
                        ],"value":_vm.filter_role,"dense":"","hide-details":""},on:{"change":_vm.onFilterRole}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":[
                {
                    text: '#',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                    value: 'no'
                },
                {
                    text: 'Imię, nazwisko, email',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                    value: 'pdata'
                },
                {
                    text: 'Status',
                    align: 'center',
                    sortable: false,
                    filterable: false,
                    value: 'status'
                },
                {
                    text: 'Poziom uprawnień',
                    align: 'center',
                    sortable: false,
                    filterable: false,
                    value: 'role'
                },
                {
                    text: 'Akcje',
                    align: 'center',
                    sortable: false,
                    filterable: false,
                    value: 'actions'
                }
            ],"items":_vm.items,"options":_vm.options,"server-items-length":_vm.pagination_number_of_items,"items-per-page":_vm.pagination_items_per_page,"item-key":"id","loading":_vm.items_loading,"footer-props":{
                'disable-items-per-page': true,
                'items-per-page-options': [_vm.pagination_items_per_page, -1]
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
            var index = ref.index;
return [_c('b',[_vm._v(_vm._s((_vm.pagination_current_page - 1) * _vm.pagination_items_per_page + index + 1))])]}},{key:"item.pdata",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('UserTile',{attrs:{"user":item,"details-key":"email"}})]}},{key:"item.role",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.$store.state.USER_ROLE_COLORS[item.role]}},[_c('div',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$store.state.USER_ROLE_LABELS[item.role])+" ")])])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.$store.state.USER_STATUSES_COLORS[item.status]}},[_c('div',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$store.state.USER_STATUSES_LABELS[item.status])+" ")])])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                            name: 'users__edit',
                            params: {
                                id: item.id
                            }
                        })}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.user_delete_dialog),callback:function ($$v) {_vm.user_delete_dialog=$$v},expression:"user_delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Potwierdź usunięcie użytkownika ")]),_c('v-card-text',[_c('p',[_vm._v(" Czy na pewno chcesz usunąć użytkownika "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.user_delete_dialog_name))]),_vm._v("? Tej operacji nie można cofnąć! ")]),_c('p',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("UWAGA!")]),_vm._v(" Usunąć można tylko konta o statusie "),_c('i',[_vm._v(_vm._s(_vm.$store.state.USER_STATUSES_LABELS['preregistered']))]),_vm._v(" lub konta typu "),_c('i',[_vm._v(_vm._s(_vm.$store.state.USER_ROLE_LABELS['user']))]),_vm._v(", które nie posiadają jeszcze żadnego wniosku. ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.user_delete_dialog = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.user_delete_dialog_loading},on:{"click":_vm.onUserDelete}},[_vm._v(" Tak, usuń ")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"450px","persistent":""},model:{value:(_vm.create_dialog),callback:function ($$v) {_vm.create_dialog=$$v},expression:"create_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Stwórz użytkownika")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-10",staticStyle:{"max-height":"80vh"}},[_c('div',[_c('v-text-field',{ref:"create_dialog_email",attrs:{"outlined":"","label":"Adres e-mail","rules":[
                                function (v) {
                                    if(!v || v.length == 0) { return 'To pole jest wymagane'; }
                                    if(!_vm.$store.state.REGEX_EMAIL.test(v)) { return 'Podaj poprawny adres e-mail'; }
                                    return true;
                                }
                            ],"type":"email","name":"email"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createUser.apply(null, arguments)}},model:{value:(_vm.create_dialog_email),callback:function ($$v) {_vm.create_dialog_email=$$v},expression:"create_dialog_email"}}),_c('v-text-field',{ref:"create_dialog_password",attrs:{"outlined":"","label":"Hasło","rules":[
                                function (v) {
                                    if(!v || v.length == 0) { return 'To pole jest wymagane'; }
                                    if(v.length < 8) { return 'Minimalna długość pola: 8 znaków'; }
                                    if(v.length > 63) { return 'Maksymalna długość pola: 63 znaki'; }
                                    if(!_vm.$store.getters['PASSWORD_VALIDATOR'](v)){
                                        return 'Hasło musi zawierać przynajmniej 1 małą literę, 1 wielką literę i 1 cyfrę';
                                    }
                                    return true;
                                }
                            ],"type":"text","name":"password"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createUser.apply(null, arguments)}},model:{value:(_vm.create_dialog_password),callback:function ($$v) {_vm.create_dialog_password=$$v},expression:"create_dialog_password"}}),_c('v-text-field',{ref:"create_dialog_name",attrs:{"outlined":"","label":"Imię i nazwisko","rules":[
                                function (v) {
                                    if(v && v.length > 31) { return 'Maksymalna długość pola: 31 znaków'; }
                                    return true;
                                }
                            ],"type":"text","name":"name"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createUser.apply(null, arguments)}},model:{value:(_vm.create_dialog_name),callback:function ($$v) {_vm.create_dialog_name=$$v},expression:"create_dialog_name"}}),_c('v-select',{attrs:{"outlined":"","label":"Poziom uprawnień","items":[
                                {
                                    text: _vm.$store.state.USER_ROLE_LABELS['admin'],
                                    value: 'admin'
                                }
                            ]},model:{value:(_vm.create_dialog_role),callback:function ($$v) {_vm.create_dialog_role=$$v},expression:"create_dialog_role"}})],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.create_dialog = false;}}},[_vm._v(" Anuluj ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":"","loading":_vm.create_dialog_loading},on:{"click":_vm.createUser}},[_vm._v(" Utwórz użytkownika ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }