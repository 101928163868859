<template>
    <Layout>
        <div>
            <v-btn
                color="grey"
                small
                text
                class="px-0 mb-1"
                @click="$router.push({
                    name: 'users__list'
                })"
            >
                <v-icon size="16" class="mr-1">mdi-arrow-left</v-icon> Powrót do listy użytkowników
            </v-btn>

            <h1 class="text-h4 mb-0">
                Edycja użytkownika
            </h1>
            <div class="grey--text mb-4">
                {{ user ? user.email : '-' }}
            </div>

            <v-fade-transition mode="out-in">
                <div v-if="user">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-sheet
                                outlined
                                rounded
                                class="pa-4"
                            >
                                <h4 class="text-h6 mt-0 mb-4 d-flex align-center">
                                    <div>
                                        Dane osobowe
                                    </div>

                                    <v-spacer></v-spacer>
                                    <v-btn
                                        icon
                                        color="grey"
                                        small
                                        title="Edytuj swoje dane"
                                        @click.stop="_ => {
                                            edit_dialog = true;
                                            edit_dialog_data = JSON.parse(JSON.stringify(user));    
                                        }"
                                        v-if="$store.getters['auth/userHasPermissions']('users.update')"
                                    >
                                        <v-icon size="20">mdi-pencil</v-icon>
                                    </v-btn>
                                </h4>

                                <v-list-item dense class="px-0">
                                    <v-list-item-content>
                                        Imię i nazwisko
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <div class="font-weight-medium">
                                            {{ user.name }}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item dense class="px-0">
                                    <v-list-item-content>
                                        <div>
                                            Stanowisko
                                            <!-- <v-tooltip right max-width="350">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon 
                                                        size="12" 
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        mdi-help-circle
                                                    </v-icon>
                                                </template>
                                                <span>
                                                    Wyświetlane upadającemu m.in. w oknie czatu, ma za zadanie zwiększać zaufanie. 
                                                </span>
                                            </v-tooltip> -->
                                        </div>
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <div class="font-weight-medium">
                                            {{ user.position || '-' }}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item dense class="px-0">
                                    <v-list-item-content>
                                        Adres e-mail
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <div class="font-weight-medium">
                                            {{ user.email }}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item dense class="px-0">
                                    <v-list-item-content>
                                        Zdjęcie profilowe
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <div> 
                                            <v-avatar
                                                :size="64"
                                                v-if="user.profile_image && user.profile_image != ''"
                                                class="mb-2"
                                            >
                                                <v-img :src="user.profile_image"></v-img>
                                            </v-avatar>
                                            <v-icon 
                                                :size="48" 
                                                color="grey"
                                                v-else
                                            >mdi-image-off</v-icon>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-sheet>

                            <!-- ACC EDIT DIALOG -->
                            <v-dialog
                                v-model="edit_dialog"
                                scrollable
                                max-width="500px"
                                persistent
                            >
                                <v-card>
                                    <v-card-title>Edytuj dane konta</v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text style="max-height: 80vh;" class="py-8" v-if="edit_dialog_data">
                                        
                                        <v-text-field 
                                            outlined
                                            label="Imię i nazwisko"
                                            v-model="edit_dialog_data.name"
                                            :rules="[
                                                v => {
                                                    if(v && v.length > 31) return 'Maksymalna długość pola: 31 znaków';
                                                    return true;
                                                }
                                            ]"
                                            ref="edit_dialog_name"
                                            type="text"
                                            name="name"
                                            @keydown.enter="onAccEdit"
                                        />

                                        <v-text-field 
                                            outlined
                                            label="Stanowisko"
                                            v-model="edit_dialog_data.position"
                                            :rules="[
                                                v => {
                                                    if(v && v.length > 31) return 'Maksymalna długość pola: 31 znaków';
                                                    return true;
                                                }
                                            ]"
                                            ref="edit_dialog_position"
                                            type="text"
                                            name="position"
                                            @keydown.enter="onAccEdit"
                                        />

                                        <v-select
                                            outlined
                                            label="Język powiadomień"
                                            :items="[
                                                {
                                                    text: 'Polski',
                                                    value: 'pl'
                                                }
                                            ]"
                                            v-model="edit_dialog_data.lang"
                                        >
                                        </v-select>
                                        
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-btn
                                            color="grey"
                                            text
                                            @click="edit_dialog = false;"
                                        >
                                            Anuluj
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="success"
                                            text
                                            :loading="edit_dialog_loading"
                                            @click="onAccEdit"
                                        >
                                            Zapisz zmiany
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-sheet
                                outlined
                                rounded
                                class="pa-4"
                            >
                                <h4 class="text-h6 mt-0 mb-4">
                                    Pozostałe informacje
                                </h4>

                                <v-list-item dense class="px-0">
                                    <v-list-item-content>
                                        Poziom uprawnień
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <div>
                                            <v-chip
                                                small
                                                :color="$store.state.USER_ROLE_COLORS[user.role]"
                                            >
                                                <div class="white--text">
                                                    {{ $store.state.USER_ROLE_LABELS[user.role] }}
                                                </div>
                                            </v-chip>

                                            <v-btn
                                                small
                                                icon
                                                color="grey"
                                                class="ml-1"
                                                title="Zmień uprawnienia konta"
                                                @click.stop="_ => {
                                                    role_change_dialog_value = user.role;
                                                    role_change_dialog = true;
                                                }"
                                                v-if="$store.getters['auth/userHasPermissions']('users.update')"
                                            >
                                                <v-icon size="18">mdi-pencil</v-icon>
                                            </v-btn>

                                            <!-- ROLE EDIT DIALOG -->
                                            <v-dialog
                                                v-model="role_change_dialog"
                                                scrollable
                                                max-width="500px"
                                                persistent
                                            >
                                                <v-card>
                                                    <v-card-title>Zmień uprawnienia konta</v-card-title>
                                                    <v-divider></v-divider>
                                                    <v-card-text style="max-height: 80vh;" class="py-8">
                                                        
                                                        <v-select
                                                            outlined
                                                            label="Uprawnienia konta"
                                                            :items="[
                                                                {
                                                                    text: $store.state.USER_ROLE_LABELS['admin'],
                                                                    value: 'admin'
                                                                }
                                                            ]"
                                                            v-model="role_change_dialog_value"
                                                            hide-details
                                                        >
                                                        </v-select>
                                                        
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                        <v-btn
                                                            color="grey"
                                                            text
                                                            @click="role_change_dialog = false;"
                                                        >
                                                            Anuluj
                                                        </v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="success"
                                                            text
                                                            :loading="role_change_dialog_loading"
                                                            @click="onAccRoleChange"
                                                        >
                                                            Zapisz zmiany
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item dense class="px-0">
                                    <v-list-item-content>
                                        Status
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <div>
                                            <v-chip
                                                small
                                                :color="$store.state.USER_STATUSES_COLORS[user.status]"
                                            >
                                                {{ $store.state.USER_STATUSES_LABELS[user.status] }}
                                            </v-chip>

                                            <v-btn
                                                small
                                                icon
                                                color="grey"
                                                class="ml-1"
                                                title="Zmień status konta"
                                                @click.stop="_ => {
                                                    status_change_dialog_value = user.status;
                                                    status_change_dialog = true;
                                                }"
                                                v-if="$store.getters['auth/userHasPermissions']('users.update')"
                                            >
                                                <v-icon size="18">mdi-pencil</v-icon>
                                            </v-btn>

                                            <v-btn
                                                small
                                                icon
                                                color="primary"
                                                class="ml-1"
                                                title="Wyślij zaproszenie do dołączenia do systemu"
                                                @click.stop="invite_dialog = true"
                                                v-if="$store.getters['auth/userHasPermissions']('users.create') && user.status == 'preregistered'"
                                            >
                                                <v-icon size="18">mdi-email-send</v-icon>
                                            </v-btn>


                                            <!-- STATUS EDIT DIALOG -->
                                            <v-dialog
                                                v-model="status_change_dialog"
                                                scrollable
                                                max-width="500px"
                                                persistent
                                            >
                                                <v-card>
                                                    <v-card-title>Zmień status konta</v-card-title>
                                                    <v-divider></v-divider>
                                                    <v-card-text style="max-height: 80vh;" class="py-8">
                                                        
                                                        <v-select
                                                            outlined
                                                            label="Status konta"
                                                            :items="[
                                                                {
                                                                    text: $store.state.USER_STATUSES_LABELS['active'],
                                                                    value: 'active'
                                                                },
                                                                {
                                                                    text: $store.state.USER_STATUSES_LABELS['suspended'],
                                                                    value: 'suspended'
                                                                },
                                                                {
                                                                    text: $store.state.USER_STATUSES_LABELS['blocked'],
                                                                    value: 'blocked'
                                                                }
                                                            ]"
                                                            v-model="status_change_dialog_value"
                                                            hide-details
                                                        >
                                                        </v-select>
                                                        
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <v-card-actions>
                                                        <v-btn
                                                            color="grey"
                                                            text
                                                            @click="status_change_dialog = false;"
                                                        >
                                                            Anuluj
                                                        </v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="success"
                                                            text
                                                            :loading="status_change_dialog_loading"
                                                            @click="onAccStatusChange"
                                                        >
                                                            Zapisz zmiany
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item dense class="px-0">
                                    <v-list-item-content>
                                        Data utworzenia konta
                                    </v-list-item-content>
                                    <v-list-item-content class="align-end">
                                        <div class="font-weight-medium">
                                            {{ (new Date(user.c_date * 1000)).toLocaleString() }}
                                        </div>
                                    </v-list-item-content>
                                </v-list-item>
                                
                                <!-- INVITE DIALOG -->
                                <v-dialog
                                    v-model="invite_dialog"
                                    scrollable
                                    max-width="500px"
                                    persistent
                                >
                                    <v-card>
                                        <v-card-title>Wyślij zaproszenie</v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text style="max-height: 80vh;" class="pt-8">
                                            
                                            <p>
                                                W wysłanej wiadomości e-mail użytkownik znajdzie link umożliwiający ukończenie procesu zakładania konta. 
                                            </p>

                                            <p>
                                                Wysłanie zaproszenia ponownie spowoduje unieważnienie wszystkich poprzednich!
                                            </p>
                                            
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                            <v-btn
                                                color="grey"
                                                text
                                                @click="invite_dialog = false;"
                                            >
                                                Anuluj
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="success"
                                                text
                                                :loading="invite_dialog_loading"
                                                @click="inviteUser"
                                            >
                                                Wyślij zaproszenie
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-sheet>

                            <v-btn
                                small
                                color="secondary"
                                class="mt-4"
                                @click.stop="pwd_reset_dialog = true"
                                v-if="$store.getters['auth/userHasPermissions']('users.update')"
                            >
                                Resetuj hasło użytkownika
                            </v-btn>
                        </v-col>
                    </v-row>


                    <!-- PWD RESET DIALOG -->
                    <v-dialog
                        v-model="pwd_reset_dialog"
                        scrollable
                        max-width="500px"
                        persistent
                    >
                        <v-card>
                            <v-card-title>Resetuj hasło użytkownika</v-card-title>
                            <v-divider></v-divider>
                            <v-card-text style="max-height: 80vh;" class="py-8">
                                
                                <v-text-field 
                                    outlined
                                    label="Nowe hasło"
                                    v-model="pwd_reset_dialog_np"
                                    :rules="[
                                        v => {
                                            if(!v || v.length == 0) return 'To pole jest wymagane';
                                            if(v.length < 8) return 'Minimalna długość pola: 8 znaków';
                                            if(v.length > 63) return 'Maksymalna długość pola: 63 znaki';
                                            if(!$store.getters['PASSWORD_VALIDATOR'](v)){
                                                return 'Hasło musi zawierać przynajmniej 1 małą literę, 1 wielką literę i 1 cyfrę';
                                            }
                                            return true;
                                        }
                                    ]"
                                    ref="pwd_reset_dialog_np"
                                    type="text"
                                    name="password"
                                    @keydown.enter="onAccPasswordReset"
                                />
                                
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn
                                    color="grey"
                                    text
                                    @click="pwd_reset_dialog = false;"
                                >
                                    Anuluj
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    text
                                    :loading="pwd_reset_dialog_loading"
                                    @click="onAccPasswordReset"
                                >
                                    Zmień hasło
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <div v-else class="py-10 text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>
            </v-fade-transition>
        </div>
    </Layout>
</template>

<script>
import waitForMs from '@/helpers/wait-for-ms';

export default {

    components: {
    },
    
    data(){
        return {
            user: null,

            edit_dialog: false,
            edit_dialog_loading: false,
            edit_dialog_data: null,

            invite_dialog: false,
            invite_dialog_loading: false,

            status_change_dialog: false,
            status_change_dialog_loading: false,
            status_change_dialog_value: 'active',

            role_change_dialog: false,
            role_change_dialog_loading: false,
            role_change_dialog_value: 'admin',

            pwd_reset_dialog: false,
            pwd_reset_dialog_loading: false,
            pwd_reset_dialog_np: ''

        }
    },

    methods: {

        async onAccEdit(){
            if(this.edit_dialog_loading) return;

            const a = [
                this.$refs.edit_dialog_name.validate(true),
                this.$refs.edit_dialog_position.validate(true)
            ];
            if(a.indexOf(false) !== -1) return;

            this.edit_dialog_loading = true;

            try{

                await this.$axios.$put(
                    "/users/" + this.user.id, 
                    this.edit_dialog_data
                );

                this.$message({
                    type: 'success',
                    msg: 'Zapisano zmiany'
                });

                this.user = this.edit_dialog_data;

                this.edit_dialog = false;

            }
            catch(err){ console.error(err); }

            this.edit_dialog_loading = false;
        },

        async onAccStatusChange(){
            if(this.status_change_dialog_loading) return;
            this.status_change_dialog_loading = true;

            try{

                await this.$axios.$put(
                    '/users/' + this.user.id + '/status',
                    {
                        status: this.status_change_dialog_value
                    }
                );

                this.$message({
                    type: 'success',
                    msg: 'Status konta został zaktualizowany'
                });

                this.user.status = this.status_change_dialog_value;
                this.status_change_dialog = false;
            }
            catch(err){ console.error(err); }

            this.status_change_dialog_loading = false;
        },

        async onAccRoleChange(){
            if(this.role_change_dialog_loading) return;
            this.role_change_dialog_loading = true;

            try{

                await this.$axios.$put(
                    '/users/' + this.user.id + '/role',
                    {
                        role: this.role_change_dialog_value
                    }
                );

                this.$message({
                    type: 'success',
                    msg: 'Uprawnienia konta zostały zaktualizowane'
                });

                this.user.role = this.role_change_dialog_value;
                this.role_change_dialog = false;
            }
            catch(err){ console.error(err); }

            this.role_change_dialog_loading = false;
        },

        async onAccPasswordReset(){
            if(this.pwd_reset_dialog_loading || !this.$refs.pwd_reset_dialog_np.validate(true)) return;
            this.pwd_reset_dialog_loading = true;

            try{

                await this.$axios.$put(
                    '/users/' + this.user.id + '/password',
                    {
                        password: this.pwd_reset_dialog_np
                    }
                );

                this.$message({
                    type: 'success',
                    msg: 'Hasło użytkownika zostało zmienione. Został on wylogowany ze wszystkich urządzeń.'
                });

                this.pwd_reset_dialog = false;
            }
            catch(err){ console.error(err); }

            this.pwd_reset_dialog_loading = false;
        },

        async inviteUser(){
            if(this.invite_dialog_loading) return;
            this.invite_dialog_loading = true;

            try{

                await waitForMs(1000);

                await this.$axios.$post(`/users/${this.user._id}/registration-invite`);

                this.$message({
                    type: 'success',
                    msg: 'Zaproszenie zostało wysłane'
                });

                this.invite_dialog = false;

            }
            catch(err){ console.error(err); }

            this.invite_dialog_loading = false;
        }

    },

    async mounted(){
        if(!this.$store.state.initial_layout_set) return;
        try{

            const r = await this.$axios.$get("/users/" + this.$route.params.id);
            this.user = r.user;

        }
        catch(err){ console.error(err); }
    },

    metaInfo(){
        return {
            title: this.user ? `Edycja użytkownika ${this.user.name}` : 'Edycja użytkownika'
        }
    }
}
</script>