export function saveInLocalStorage(key, value){
    if(window.localStorage){
        localStorage.setItem(key, value);
    }
}

export function retrieveFromLocalStorage(key){
    if(window.localStorage){
        return localStorage.getItem(key);
    }
}

export function removeFromLocalStorage(key){
    if(window.localStorage){
        return localStorage.removeItem(key);
    }
}