<template>
    <Layout>
        <div>
            <h1 class="text-h4 mb-8 d-flex align-center flex-wrap">
                <div>
                    Lista użytkowników
                    <p class="text-body-2 grey--text">
                        mających dostęp do panelu administracyjnego
                    </p>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    color="success"
                    small
                    class="my-2"
                    v-if="$store.getters['auth/userHasPermissions']('users.create')"
                    @click.stop="create_dialog = true"
                >
                    Stwórz użytkownika
                </v-btn>
            </h1>
            
            <v-sheet
                color="grey lighten-4"
                rounded
                class="pa-4 mb-8"
            >
                <h4 class="text-h6 mt-0 mb-2">
                    Filtry
                </h4>
                <v-row>
                    <!-- <v-col xs="12" md="4">
                        <div class="d-flex">
                            <v-text-field
                                dense
                                outlined
                                label="Wyszukaj po nazwisku/mailu"
                                :rules="[
                                    v => {
                                        if(!v || v == '' || v.length < 3){
                                            return 'Podaj przynajmniej 3 znaki aby wyszukać';
                                        }
                                        return true;
                                    }
                                ]"
                                v-model="filter_search"
                                ref="filter_search"
                                @keydown.enter="onFilterSearch"
                            ></v-text-field>
                            <v-btn
                                color="primary"
                                icon
                                class="ml-1"
                                @click="onFilterSearch"
                                title="Wyszukaj"
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                            <v-btn
                                color="error"
                                v-if="fliter_search_applied"
                                icon
                                @click="onFilterSearchClear"
                                title="Wyczyść wyszukiwanie"
                            >
                                <v-icon>mdi-backspace</v-icon>
                            </v-btn>
                        </div>
                    </v-col> -->
                    <v-col xs="12" md="4">
                        <v-select
                            dense
                            outlined
                            label="Status konta"
                            :items="[
                                {
                                    text: 'Wszystkie statusy',
                                    value: ''
                                },
                                {
                                    text: $store.state.USER_STATUSES_LABELS['active'],
                                    value: 'active'
                                },
                                {
                                    text: $store.state.USER_STATUSES_LABELS['blocked'],
                                    value: 'blocked'
                                },
                                {
                                    text: $store.state.USER_STATUSES_LABELS['suspended'],
                                    value: 'suspended'
                                }
                            ]"
                            :value="filter_status"
                            @change="onFilterStatus"
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col xs="12" md="4">
                        <v-select
                            outlined
                            label="Poziom uprawnień"
                            :items="[
                                {
                                    text: 'Wszystkie poziomy',
                                    value: ''
                                },
                                {
                                    text: $store.state.USER_ROLE_LABELS['admin'],
                                    value: 'admin'
                                }
                            ]"
                            :value="filter_role"
                            @change="onFilterRole"
                            dense
                            hide-details
                        ></v-select>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-data-table
                :headers="[
                    {
                        text: '#',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'no'
                    },
                    {
                        text: 'Imię, nazwisko, email',
                        align: 'start',
                        sortable: false,
                        filterable: false,
                        value: 'pdata'
                    },
                    {
                        text: 'Status',
                        align: 'center',
                        sortable: false,
                        filterable: false,
                        value: 'status'
                    },
                    {
                        text: 'Poziom uprawnień',
                        align: 'center',
                        sortable: false,
                        filterable: false,
                        value: 'role'
                    },
                    {
                        text: 'Akcje',
                        align: 'center',
                        sortable: false,
                        filterable: false,
                        value: 'actions'
                    }
                ]"
                :items="items"
                :options.sync="options"
                :server-items-length="pagination_number_of_items"
                :items-per-page="pagination_items_per_page"
                item-key="id"
                :loading="items_loading"
                :footer-props="{
                    'disable-items-per-page': true,
                    'items-per-page-options': [pagination_items_per_page, -1]
                }"
            >
                <template v-slot:item.no="{ index }">
                    <b>{{ (pagination_current_page - 1) * pagination_items_per_page + index + 1 }}</b>
                </template>

                <template v-slot:item.pdata="{ item, index }">
                    <UserTile 
                        :user="item"
                        details-key="email"
                    />
                </template>

                <template v-slot:item.role="{ item }">
                    <v-chip
                        small
                        :color="$store.state.USER_ROLE_COLORS[item.role]"
                    >
                        <div class="white--text">
                            {{ $store.state.USER_ROLE_LABELS[item.role] }}
                        </div>
                    </v-chip>
                </template> 

                <template v-slot:item.status="{ item }">
                    <v-chip
                        small
                        :color="$store.state.USER_STATUSES_COLORS[item.status]"
                    >
                        <div class="white--text">
                            {{ $store.state.USER_STATUSES_LABELS[item.status] }}
                        </div>
                    </v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn 
                        icon
                        @click="
                            $router.push({
                                name: 'users__edit',
                                params: {
                                    id: item.id
                                }
                            })
                        "
                    >
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <!-- <v-btn 
                        icon
                        color="error"
                        @click.stop="v => {
                            user_delete_dialog = true;
                            user_delete_dialog_id = item._id;
                            user_delete_dialog_name = `${item.name} (${item.email})`;
                        }"
                        v-if="$store.getters['auth/userHasPermissions']('users.delete')"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn> -->
                </template>
            </v-data-table>

            <!-- DELETE DIALOG -->
            <v-dialog
                v-model="user_delete_dialog"
                max-width="450"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        Potwierdź usunięcie użytkownika
                    </v-card-title>

                    <v-card-text>
                        <p>
                            Czy na pewno chcesz usunąć użytkownika <span class="font-weight-bold">{{user_delete_dialog_name}}</span>? Tej operacji nie można cofnąć!
                        </p>
                        <p>
                            <span class="font-weight-medium">UWAGA!</span> Usunąć można tylko konta o statusie <i>{{ $store.state.USER_STATUSES_LABELS['preregistered'] }}</i> lub konta typu <i>{{ $store.state.USER_ROLE_LABELS['user'] }}</i>, które nie posiadają jeszcze żadnego wniosku.
                        </p>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="grey"
                            text
                            @click="user_delete_dialog = false"
                        >
                            Anuluj
                        </v-btn>

                        <v-btn
                            color="error"
                            text
                            @click="onUserDelete"
                            :loading="user_delete_dialog_loading"
                        >
                            Tak, usuń
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ACC CREATE DIALOG -->
            <v-dialog
                v-model="create_dialog"
                scrollable
                max-width="450px"
                persistent
            >
                <v-card>
                    <v-card-title>Stwórz użytkownika</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="max-height: 80vh;" class="pt-10">
                        <div>

                            <v-text-field 
                                outlined
                                label="Adres e-mail"
                                v-model="create_dialog_email"
                                :rules="[
                                    v => {
                                        if(!v || v.length == 0) return 'To pole jest wymagane';
                                        if(!$store.state.REGEX_EMAIL.test(v)) return 'Podaj poprawny adres e-mail';
                                        return true;
                                    }
                                ]"
                                ref="create_dialog_email"
                                type="email"
                                name="email"
                                @keydown.enter="createUser"
                            />

                            <v-text-field 
                                outlined
                                label="Hasło"
                                v-model="create_dialog_password"
                                :rules="[
                                    v => {
                                        if(!v || v.length == 0) return 'To pole jest wymagane';
                                        if(v.length < 8) return 'Minimalna długość pola: 8 znaków';
                                        if(v.length > 63) return 'Maksymalna długość pola: 63 znaki';
                                        if(!$store.getters['PASSWORD_VALIDATOR'](v)){
                                            return 'Hasło musi zawierać przynajmniej 1 małą literę, 1 wielką literę i 1 cyfrę';
                                        }
                                        return true;
                                    }
                                ]"
                                ref="create_dialog_password"
                                type="text"
                                name="password"
                                @keydown.enter="createUser"
                            />

                            <v-text-field 
                                outlined
                                label="Imię i nazwisko"
                                v-model="create_dialog_name"
                                :rules="[
                                    v => {
                                        if(v && v.length > 31) return 'Maksymalna długość pola: 31 znaków';
                                        return true;
                                    }
                                ]"
                                ref="create_dialog_name"
                                type="text"
                                name="name"
                                @keydown.enter="createUser"
                            />

                            <v-select
                                outlined
                                label="Poziom uprawnień"
                                :items="[
                                    {
                                        text: $store.state.USER_ROLE_LABELS['admin'],
                                        value: 'admin'
                                    }
                                ]"
                                v-model="create_dialog_role"
                            >
                            </v-select>



                        </div>         
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn
                            color="grey"
                            text
                            @click="create_dialog = false;"
                        >
                            Anuluj
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="success"
                            text
                            :loading="create_dialog_loading"
                            @click="createUser"
                        >
                            Utwórz użytkownika
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </Layout>
</template>

<script>
import { saveInLocalStorage, retrieveFromLocalStorage, removeFromLocalStorage } from '@/helpers/local-storage';

export default {

    components: {
    },

    data(){
        return {

            items: [],
            items_fetched: false,
            items_loading: false,

            options: {
                page: 1
            },

            pagination_current_page: 1,
            pagination_number_of_pages: 1,
            pagination_items_per_page: 1,
            pagination_number_of_items: 1,

            filter_status: '',
            filter_role: '',
            filter_search: '',
            fliter_search_applied: false,

            user_delete_dialog: false,
            user_delete_dialog_loading: false,
            user_delete_dialog_id: null,
            user_delete_dialog_name: '',

            create_dialog: false,
            create_dialog_loading: false,
            create_dialog_email: '',
            create_dialog_password: '',
            create_dialog_name: '',
            create_dialog_role: 'admin'

        }
    },

    watch: {
        options: {
            handler (newValue) {
                if(newValue.page != undefined && newValue.page != this.pagination_current_page){
                    this.onPaginate(newValue.page);
                }
            },
            deep: true,
        }
    },

    methods:{

        onPaginate(page){
            this.pagination_current_page = page;
            this.options.page = page;
            saveInLocalStorage('ppomadm-users-list-pagination-current_page', page);
            this.fetchData();
        },
        onFilterStatus(v){
            this.filter_status = v;
            if(v !== ''){
                saveInLocalStorage('ppomadm-users-list-filter_status', v);
            }
            else{
                removeFromLocalStorage('ppomadm-users-list-filter_status');
            }
            this.onPaginate(1);
        },
        onFilterRole(v){
            this.filter_role = v;
            if(v !== ''){
                saveInLocalStorage('ppomadm-users-list-filter_role', v);
            }
            else{
                removeFromLocalStorage('ppomadm-users-list-filter_role');
            }
            this.onPaginate(1);
        },
        onFilterSearch(){
            if(!this.$refs.filter_search.validate(true)) return;
            this.fliter_search_applied = true;
            saveInLocalStorage('ppomadm-users-list-filter_search', this.filter_search);
            this.onPaginate(1);

        },
        onFilterSearchClear(){
            this.filter_search = "";
            this.fliter_search_applied = false;
            removeFromLocalStorage('ppomadm-users-list-filter_search');
            this.$refs.filter_search.resetValidation();
            this.onPaginate(1);
        },

        fetchData(){
            return new Promise(async (resolve, reject) => {

                if(this.items_loading) return resolve(false);
                this.items_loading = true;

                try{

                    const r = await this.$axios.$get(
                        `/users/?page=${this.pagination_current_page}&filter_status=${this.filter_status}&filter_role=${this.filter_role}&filter_search=${this.fliter_search_applied ? encodeURIComponent(this.filter_search) : ''}`
                    );  
                    // console.log(r);

                    this.items_fetched = true;
                    this.items = r.users;
                    this.pagination_current_page = parseInt(r.pagination.current_page);
                    this.options.page = r.pagination_current_page;
                    this.pagination_number_of_pages = parseInt(r.pagination.number_of_pages);
                    this.pagination_items_per_page = parseInt(r.pagination.items_per_page);
                    this.pagination_number_of_items = parseInt(r.pagination.number_of_items);

                }
                catch(err){
                    return reject(err);
                }

                this.items_loading = false;
                return resolve(true);
            });
        },

        // usuwanie użytkownika 
        async onUserDelete(){
            if(this.user_delete_dialog_loading || !this.user_delete_dialog_id) return;
            this.user_delete_dialog_loading = true;

            try{

                await this.$axios.$delete("/users/" + this.user_delete_dialog_id);

                const ix = this.items.findIndex(item => item._id == this.user_delete_dialog_id);
                if(ix !== -1){
                    this.items.splice(ix, 1);
                }

                this.$message({
                    type: 'success',
                    msg: 'Użytkownik ' + this.user_delete_dialog_name + ' został usunięty'
                });

                this.user_delete_dialog = false;
                this.user_delete_dialog_id = null;
                this.user_delete_dialog_name = '';

            }
            catch(err){ console.error(err); }

            this.user_delete_dialog_loading = false;
        },

        // tworzenie użytkownika
        async createUser(){
            if(this.create_dialog_loading) return;

            const a = [
                this.$refs.create_dialog_email.validate(true),
                this.$refs.create_dialog_name.validate(true),
                this.$refs.create_dialog_password.validate(true)
            ];
            if(a.indexOf(false) !== -1) return;

            this.create_dialog_loading = true;

            try{

                const r = await this.$axios.$post("/users", {
                    email: this.create_dialog_email,
                    name: this.create_dialog_name,
                    role: this.create_dialog_role,
                    password: this.create_dialog_password
                });

                this.items.unshift(r.user);
                this.pagination_number_of_items += 1;

                this.create_dialog_email = this.create_dialog_name = '';
                this.create_dialog_role = 'admin';
                this.$refs.create_dialog_email.resetValidation();
                this.$refs.create_dialog_name.resetValidation();
                this.$refs.create_dialog_password.resetValidation();

                this.$message({
                    type: 'success',
                    msg: 'Użytkownik został dodany pomyślnie'
                });

                this.create_dialog = false;

            }
            catch(err){ console.error(err); }

            this.create_dialog_loading = false;
        }

    },

    async mounted(){

        
        if(!this.$store.state.initial_layout_set) return;

        // pobranie ew. zapisanych danych filtrowania i paginacji
        if(retrieveFromLocalStorage('ppomadm-users-list-pagination-current_page')){
            this.pagination_current_page = parseInt(retrieveFromLocalStorage('ppomadm-users-list-pagination-current_page'));
            this.options.page = this.pagination_current_page;
        }

        if(retrieveFromLocalStorage('ppomadm-users-list-filter_search')){
            this.filter_search = retrieveFromLocalStorage('ppomadm-users-list-filter_search');
            this.fliter_search_applied = true;
        }

        if(retrieveFromLocalStorage('ppomadm-users-list-filter_status')){
            this.filter_status = retrieveFromLocalStorage('ppomadm-users-list-filter_status');
        }

        if(retrieveFromLocalStorage('ppomadm-users-list-filter_role')){
            this.filter_role = retrieveFromLocalStorage('ppomadm-users-list-filter_role');
        }

        await this.fetchData();
    },
    
    metaInfo: {
        title: 'Użytkownicy'
    }
}
</script>